import { m } from 'framer-motion';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';

import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';

import { Link } from '@mui/material';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { useBoolean } from 'src/hooks/use-boolean';
import NotificationHelperDialog from 'src/sections/notification-help/notification-helper-dialog';
import OrganizationConfigEdit from 'src/sections/organization-config/organization-config-edit';
import OrganizationQuickEdit from 'src/sections/organization/organization-quick-edit';
import UserQuickEditForm from 'src/sections/user/user-quick-edit-form';
import { CONTRACT_URL } from 'src/types/contract';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();
  const { user, selectedOrganization, logout } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const popover = usePopover();

  const quickEditUser = useBoolean();
  const quickEditOrganization = useBoolean();
  const organizationConfigEdit = useBoolean();
  const notificationHelper = useBoolean();

  const contractUrl = sessionStorage.getItem(CONTRACT_URL);

  const handleOpenProfile = () => { 
    popover.onClose();
    quickEditUser.onTrue();
  }

  const handleChangeOrganization = () => {
    popover.onClose();
    router.push('/list-organization');
  }

  const handleOpenOrganizationProfile = () => {
    popover.onClose();
    quickEditOrganization.onTrue();
  }

  const handleOpenOrganizationConfig = () => {
    popover.onClose();
    organizationConfigEdit.onTrue();
  }

  const OPTIONS = [
    {
      label: 'Mudar de organização',
      onClick: () => handleChangeOrganization(),
    },
    {
      label: 'Perfil da organização',
      onClick: () => handleOpenOrganizationProfile(),
    },
    {
      label: 'Configurações da organização', //TODO precisa ter permissao
      onClick: () => handleOpenOrganizationConfig(),
    },
    {
      label: 'Perfil do usuário',
      onClick: () => handleOpenProfile(),
    }
  ];

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.push("/");
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.logoPath}
          alt={user?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.name.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 230, p: 0 }}>
        <Box sx={{ p: 1, pb: 1 }}>
          <MenuItem onClick={handleOpenOrganizationProfile}>
            <Typography variant="subtitle2" noWrap>
                {selectedOrganization?.name}
            </Typography>
          </MenuItem>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem 
              key={option.label} 
              onClick={option.onClick}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack sx={{ p: 1}}>
            <MenuItem>
              <Link 
                href={contractUrl ?? ''} 
                target={"_blank"}
                style={{
                  textDecoration: 'none'
                }}
              >
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary',
                    gap: '4px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }} 
                  noWrap
                >
                  Termos de uso
                  <Iconify icon={"ion:arrow-up-right-box-outline"} width={14}/>
                </Typography>
              </Link>
            </MenuItem>
          </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Sair
        </MenuItem>
      </CustomPopover>
      
      { quickEditUser.value &&
        <UserQuickEditForm 
          open={quickEditUser.value} 
          onClose={quickEditUser.onFalse}
          onOpenNotificationHelper={() => {
            quickEditUser.onFalse();
            notificationHelper.onTrue();
          }}
        />
      }

      { quickEditOrganization.value &&
        <OrganizationQuickEdit
          open={quickEditOrganization.value} 
          onClose={quickEditOrganization.onFalse} 
        />
      }

      { organizationConfigEdit.value &&
        <OrganizationConfigEdit
          open={organizationConfigEdit.value} 
          onClose={organizationConfigEdit.onFalse} 
        />
      }

      { notificationHelper.value &&
        <NotificationHelperDialog 
          open={notificationHelper.value}
          onClose={notificationHelper.onFalse}
        />
      }
    </>
  );
}
